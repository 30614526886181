import { ProgressiveImage } from "components/Image/Image";
import s from "./style.module.scss";
import { useEffect, useState } from "react";

export function PageIndexThumbs(data) {
	const fullSrc = data.project.attributes.Thumbnail.data?.attributes.formats
		.medium
		? data.project.attributes.Thumbnail.data?.attributes.formats.medium.url
		: null;

	return (
		<div key={data.index} className={`thumb ${s.thumbContainer}`}>
			<ProgressiveImage
				key={data.index}
				fullSrc={fullSrc}
				isThumbLoadedFinish={undefined}
				width={
					data.project.attributes.Thumbnail.data?.attributes.formats
						.width
				}
				height={
					data.project.attributes.Thumbnail.data?.attributes.formats
						.height
				}
				alt={
					data.project.attributes.Thumbnail.data?.attributes.formats
						.alternativeText
				}
				srcSet={
					data.project.attributes.Thumbnail.data?.attributes.formats
				}
			/>
		</div>
	);
}
