import s from "./style.module.css";
import { useEffect, useState } from "react";
export function Logo({ title, pourcentage, onClick }) {
	const [isScroll, setIsScroll] = useState(false);

	useEffect(() => {
		setIsScroll(true);

		const timer = setTimeout(() => {
			setIsScroll(false);
		}, 800);

		return () => {
			clearTimeout(timer);
		};
	}, [pourcentage]);

	return (
		<a href="/" onClick={onClick}>
			<div className={s.container}>
				<div className={`${s.logo_txt} ${isScroll ? s.hide : ""}`}>
					{title}
				</div>
				<div className={`${s.pourcentage} ${isScroll ? s.show : ""}`}>
					{pourcentage}%
				</div>
			</div>
		</a>
	);
}
