import { gsap } from "gsap";

export class Animation {
	constructor() {}

	set(to, from, reverse = false) {
		this.prev = from;
		this.next = to;

		if (reverse) {
			this.prev = to;
			this.next = from;
		}

		this.reverse = reverse;
		this.anim();
	}

	anim() {
		if (this.tl && this.tl.progress() !== 1 && this.tl.progress() !== 0) {
			return false;
		}

		this.tl = gsap.timeline({
			paused: true,
		});

		if (this.prev && this.next) {
			this.tl.fromTo(
				this.prev,
				{
					zIndex: -1,
					opacity: 1,
					scale: 1,
					y: "0vh",
					height: "100vh",
				},
				{
					scale: 0.9,
					duration: 1.5,
					opacity: 1,
					zIndex: 1,
					height: "100vh",
					y: "5vh",
					ease: "expo.inOut",
				}
			);

			this.tl.fromTo(
				this.next,
				{
					zIndex: 2,
					opacity: 1,
					scale: 1,
					height: "0vh",
					y: "0vh",
				},
				{
					duration: 1.5,
					height: "100vh",
					ease: "expo.inOut",
				},
				"<"
			);
		}

		if (!this.reverse) {
			this.tl.play();
		} else {
			this.tl.progress(1);
			this.tl.timeScale(1.8);
			this.tl.reverse();
		}
	}
}
