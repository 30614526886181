import { BigTitle } from "components/BigTitle/BigTitle";
import { ProjectList } from "components/ProjectList/ProjectList";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBlend, setColor } from "store/header/header-slice";
import PageAnimation from "components/pageAnimation/pageAnimation";

import s from "./style.module.css";

export function ProjectBrowse() {
	const dispatch = useDispatch();




	useEffect(() => {
		dispatch(setBlend(true));
		dispatch(setColor("#000"));
	}, [dispatch]);

	return (
		<PageAnimation>
			<div className={`home ${s.container}`}>
				{window.innerWidth > 768 && <BigTitle />}
				<ProjectList />
			</div>
		</PageAnimation>
	);
}
