import { AboutComponents } from "components/AboutComponents/AboutComponents";
import { Scroller } from "components/Scroller/Scroller";
import PageAnimation from "components/pageAnimation/pageAnimation";
import s from "./style.module.scss";

export function PageNotFound(props) {
	return (
		<PageAnimation>
			<Scroller save={true} slug={"notfound"}>
				<div className={`others ${s.container}`}>
					<div key="0" className={s.wrapper}>

						<AboutComponents
							key="0"
							type="about.texte-d-introduction"
							content={{Introduction: "404"}}
						/>

						<AboutComponents
							key="1"
							type="about.description"
							content={{Description: "Page not found"}}
						/>
					</div>
				</div>
			</Scroller>
		</PageAnimation>
	);
}
