import { Video } from "components/Video/Video";

import placeholder from "./placeholder_1920x1080.png";
import ui from "./ui.svg";

import locker from "./locker.jpg";
require("./MockupTablet.scss");
export function MockupTablet({
	url,
	color,
	mime,
	fakeUrl,
	contain = false,
	background = "black",
}) {
	return (
		<div className={`mockup-tablet`}>
			<div className="mockup-tablet__container">
				<div className="mockup-tablet__v2">
					<div className="mockup-tablet__coque">
						<img
							src={placeholder}
							alt=""
							width={1920}
							height={1080}
						/>
						<svg viewBox="0 0 1920 1080">
							<path
								d="m1524.11 86.01-981.94.02v-1.37c.02-.32-.1-.66-.32-.91s-.54-.39-.87-.41h-48.57c-.33.02-.65.17-.87.41s-.34.58-.32.91v1.37h-10.3v-1.37c.02-.32-.1-.66-.32-.91s-.54-.39-.87-.41h-48.57a1.3 1.3 0 0 0-.87.41c-.22.25-.34.58-.32.91v1.37H398.3c-37.18 0-66.28 23.59-67.82 64.47h-1.16c-.33-.01-.65.1-.9.32s-.39.53-.41.86v58.88c.02.33.17.64.41.86.25.22.57.34.9.32h1.1v716.99c0 43 29.68 67.91 67.89 67.91l1125.81-.02c38.22 0 67.89-24.92 67.89-67.91v-774.8c-.01-42.98-29.68-67.9-67.9-67.9zm25.26 848.65c0 8.7-7.05 15.75-15.75 15.75H390c-8.7 0-15.75-7.05-15.75-15.75V145.34c0-8.7 7.05-15.75 15.75-15.75h1143.62c8.7 0 15.75 7.05 15.75 15.75v789.32z"
								fill={color ? color : "#fff"}
							/>
						</svg>
					</div>

					<div className="mockup-tablet__ui">
						<img src={ui} alt="" />
					</div>
				</div>

				<div className="mockup-tablet__fakeurl">
					<div className="mockup-tablet__fakeurl__background"></div>
					<div>
						<img src={locker} alt="locker" />
						{fakeUrl}
					</div>
				</div>
				<div
					className="mockup-tablet__video"
					style={{ background: background }}
				>
					<Video url={url} mime={mime} contain={contain} />
				</div>
			</div>
		</div>
	);
}
