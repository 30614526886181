import React, { useRef, useEffect } from "react";
import s from "./style.module.css";

export function Video({
	url,
	width = "100%",
	height = "100%",
	mime,
	contain = null,
}) {
	const videoRef = useRef(null);

	useEffect(() => {
		const video = videoRef.current;

		const preventFullscreen = () => {
			if (video.webkitEnterFullscreen) {
				video.webkitEnterFullscreen = null; // Désactive la fonctionnalité de plein écran pour Safari
			}

			if (video.mozRequestFullScreen) {
				video.mozRequestFullScreen = null; // Désactive la fonctionnalité de plein écran pour Firefox
			}

			if (video.msRequestFullscreen) {
				video.msRequestFullscreen = null; // Désactive la fonctionnalité de plein écran pour Internet Explorer/Edge
			}

			document.removeEventListener("fullscreenchange", preventFullscreen);
		};

		// Ajoute un événement pour détecter le lancement en plein écran
		document.addEventListener("fullscreenchange", preventFullscreen);

		return () => {
			document.removeEventListener("fullscreenchange", preventFullscreen);
		};
	}, []);

	return (
		<video
			className={`${s.item} ${contain ? s.contain : s.cover}`}
			autoPlay
			muted
			playsInline
			loop
			ref={videoRef}
			width={width}
			height={height}
		>
			<source type={mime} src={process.env.REACT_APP_BASE_URL + url} />
		</video>
	);
}
