import s from "./style.module.scss";
import { Category } from "components/Category/Category";
export function ManifestProject({ project }) {
	let client = null;
	let types = null;
	let categories = null;

	if (project?.attributes?.clients?.data === undefined) return null;

	if (Object.keys(project.attributes.clients.data).length > 0) {
		client = project.attributes.clients.data[0].attributes.Name;
		types = project.attributes.clients.data[0].attributes.types.data;
		categories = project.attributes.categories.data;
	}

	return (
		<div className={s.container}>
			{client && (
				<div className={`client ${s.client}`}>
					<h2>client</h2>
					<div className={s.items}>
						<span>{client}</span>
					</div>
				</div>
			)}
			{types && (
				<div className={`type ${s.types}`}>
					<h2>secteur</h2>
					<div className={s.items}>
						{types.map((type, index) => (
							<span key={index}>
								{type.attributes.Type}
								{index < types.length - 1 && " — "}
							</span>
						))}
					</div>
				</div>
			)}
			{categories && (
				<div className={`categories ${s.categories}`}>
					<h2>catégories</h2>
					<div className={`${s.items}> ${s.itemsCategories}`}>
						{categories.map((category, index) => (
							<Category
								key={index}
								title={category.attributes.Name}
								styleItem="project"
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
