import { configureStore } from "@reduxjs/toolkit";

import { headerReducer } from "./header/header-slice";
import { projectReducer } from "./project/project-slice";
import { aboutReducer } from "./about/about-slice";
import { indexReducer } from "./index/index-slice";
import { clientReducer } from "./client/client-slice";
import { secteurReducer } from "./secteur/secteur-slice";
import { scrollerReducer } from "./scroller/scroller-slice";
import { bigtitleReducer } from "./bigtitle/bigtitle-slice";
import { loaderReducer } from "./loader/loader.slice";
import { transitionReducer } from "./transition/transition-slice";
import { othersReducer } from "./others/others-slice";
export const store = configureStore({
	reducer: {
		PROJECT: projectReducer,
		HEADER: headerReducer,
		ABOUT: aboutReducer,
		INDEX: indexReducer,
		CLIENT: clientReducer,
		SECTEUR: secteurReducer,
		SCROLLER: scrollerReducer,
		BIGTITLE: bigtitleReducer,
		LOADER: loaderReducer,
		TRANSITION: transitionReducer,
		OTHERS: othersReducer,
	},
});
