import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "clientSlice",
  initialState: {
    clientList: [],
  },
  reducers: {
    setClientList: (currentSlice, action) => {
      currentSlice.clientList = action.payload;
    },
  },
});

export const clientReducer = clientSlice.reducer;
export const { setClientList } = clientSlice.actions;
