import s from "./style.module.scss";
import { Video } from "components/Video/Video";
import { ProgressiveImage } from "components/Image/Image";

import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { ManifestProject } from "components/ManifestProject/ManifestProject";
import { useEffect } from "react";
import { MockupMobile } from "components/MockupMobile/MockupMobile";
import { MockupTablet } from "components/MockupTablet/MockupTablet";

export function ProjectComponents({ type, content, project, index }) {
	const Contents = [];

	switch (type) {
		case "project.visuel":
			if (!content.Visuel.data) break;

			Contents.push(
				<section
					key={index}
					className={
						index === 0
							? `${s.container} ${s.visuel} ${s.visuelFirst}`
							: `${s.container} ${s.visuel}`
					}
				>
					<ProgressiveImage
						key={content.Visuel.data.id}
						fullSrc={content.Visuel.data.attributes.url}
						srcSet={content.Visuel.data.attributes.formats}
						width={
							content.Visuel.data.attributes.width
						}
						height={
							content.Visuel.data.attributes.height
						}
						alt={content.Visuel.data.attributes.alt}
						contain={content.Contain}
					/>
				</section>
			);
			break;
		case "project.video":
			if (!content.Video.data) break;

			if (content.mobile) {
				Contents.push(
					<section
						key={index}
						className={
							index === 0
								? `${s.container} ${s.visuel} ${s.visuelFirst}`
								: `${s.container} ${s.visuel}`
						}
					>
						<MockupMobile
							url={content.Video.data.attributes.url}
							color={content.mockupcolor}
							mime={content.Video.data.attributes.mime}
							fakeUrl={content.mockupurl}
							contain={content.Contain}
							background={content.mockerbackground}
						/>
					</section>
				);
			}else if(content.ipad){
				Contents.push(
					<section
						key={index}
						className={
							index === 0
								? `${s.container} ${s.visuel} ${s.visuelFirst}`
								: `${s.container} ${s.visuel}`
						}
					>
						<MockupTablet
							url={content.Video.data.attributes.url}
							color={content.mockupcolor}
							mime={content.Video.data.attributes.mime}
							fakeUrl={content.mockupurl}
							contain={content.Contain}
							background={content.mockerbackground}
						/>
					</section>
				);
			} 
			else {
				Contents.push(
					<section
						key={index}
						className={
							index === 0
								? `${s.container} ${s.visuel} ${s.visuelFirst}`
								: `${s.container} ${s.visuel}`
						}
					>
						<Video
							url={content.Video.data.attributes.url}
							mime={content.Video.data.attributes.mime}
							contain={content.Contain}
						/>
					</section>
				);
			}

			break;
		case "project.texte":
			const align =
				content.Alignement === null || content.Alignement === "center"
					? s.center
					: content.Alignement === "left"
					? s.left
					: s.right;
			Contents.push(
				<section
					key={index}
					className={`project-description ${s.container}  ${s.texte} ${align} `}
				>
					<MarkDownBreakLine>{content.Texte}</MarkDownBreakLine>
				</section>
			);
			break;
		default:
			Contents.push(
				<section key={index} className={`${s.container}  ${s.texte}`}>
					<MarkDownBreakLine>{content.Texte}</MarkDownBreakLine>
				</section>
			);
	}

	if (index === 0) {
		Contents.push(
			<section
				key={`manifest ${index}`}
				className={`${s.manifest} ${s.container}`}
			>
				<ManifestProject project={project} />
			</section>
		);
	}

	return Contents;
}
function MarkDownBreakLine(props) {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkBreaks]}
			children={props.children.replace(/\n/gi, "&nbsp; \n")}
		/>
	);
}
