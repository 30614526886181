import { Categories } from "components/Categories/Categories";
import { ProgressiveImage } from "components/Image/Image";
import { useState } from "react";
import s from "./style.module.scss";

export function ThumbCard({ title, thumbImg, categories, onClickNavigate }) {
	const [isCardHovered, setIsCardHovered] = useState(false);
	const [isThumbLoaded, setIsThumbLoaded] = useState(false);

	const isThumbLoadedFinish = () => {
		setIsThumbLoaded(true);
	};
	return (
		<div
			className={`${s.item} ${
				isThumbLoaded ? " " + s.itemThumbLoaded : ""
			}`}
			onClick={onClickNavigate}
			onMouseEnter={() => setIsCardHovered(true)}
			onMouseLeave={() => setIsCardHovered(false)}
		>
			<div className={s.title}>
				<div className="card-title">{title}</div>
			</div>
			<div className={`${s.body}`}>
				<div className={s.categories}>
					<Categories categoriesList={categories} />
				</div>
				<div
					className={`${s.thumb}${
						isCardHovered ? " " + s.thumbHover : ""
					}`}
				>
					<ProgressiveImage
						key={thumbImg.id}
						fullSrc={thumbImg.attributes.url}
						srcSet={thumbImg.attributes.formats}
						isThumbLoadedFinish={isThumbLoadedFinish}
						width={thumbImg.attributes.formats.medium.width}
						height={thumbImg.attributes.formats.medium.height}
						alt={thumbImg.attributes.alt}
						max={'medium'}
					/>
				</div>
			</div>
		</div>
	);
}
