import React, { useEffect, useState } from "react";
import { PageAbout } from "pages/PageAbout/PageAbout";
import { PageIndex } from "pages/PageIndex/PageIndex";
import { PageNotFound } from "pages/PageNotFound/PageNotFound";
import { PageOther } from "pages/PageOthers/PageOther";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setBlend, setColor } from "store/header/header-slice";

const Page = () => {
	const { slug } = useParams();
	const pages = useSelector((store) => store.OTHERS.data);
	const [colorText, setColorText] = useState("#fff");
	const dispatch = useDispatch();

	useEffect(() => {
		if (Object.keys(pages).length === 0) return;
		const pageData = pages.find(
			(page) => page.attributes.slug === slug
		)?.attributes;

		if (pageData) {
			dispatch(setBlend(true));
			const textColor = pageData.color
				? pageData.color
				: slug === "index"
				? "#000"
				: "#fff";

			// console.log(slug);

			setTimeout(() => {
				dispatch(setColor(textColor));
			}, 1200);
		}
	}, [pages]);

	if (Object.keys(pages).length === 0) return <div>loading</div>;
	const pageData = pages.find(
		(page) => page.attributes.slug === slug
	)?.attributes;
	const pagesStatic = {
		about: {
			content: <PageAbout />,
		},
		index: {
			content: <PageIndex />,
		},
	};

	const content = !pageData ? (
		<PageNotFound />
	) : (
		pagesStatic[slug]?.content || <PageOther slug={slug} />
	);

	return content;
};

export { Page };
