const set100vh = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
};
set100vh()


export const getWindowWidth = () => {
	if (typeof window !== 'undefined') {
	  return window.innerWidth;
	}
	return null;
  };