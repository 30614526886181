import axios from "axios";

export class PagesAPI {
	constructor() {
		this.data = {};
	}

	static async fetchPages() {
		const env = process.env.REACT_APP_NODE_ENV;

		const url =
			env === "development" || env === "staging"
				? `${process.env.REACT_APP_BASE_URL}/api/pages/?populate=*`
				: `${process.env.REACT_APP_BASE_URL}/api/pages/`;

		return (await axios.get(url)).data.data;
	}
}
