import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar";
import { setPourcentage } from "store/header/header-slice";
import { setOffset } from "store/scroller/scroller-slice";
export class Scroll {
	constructor() {
		this.listener = this.listener.bind(this);
		this.stop = this.stop.bind(this);
	}
	init(
		el,
		direction = "y",
		dispatch,
		delegateGlobal = false,
		item,
		save = null
	) {
		this.roll = true;
		this.DOM = el;
		this.direction = direction;
		this.dispatch = dispatch;
		this.options = {
			alwaysShowTracks: false,
			continuousScrolling: false,
			damping: 0.1,
		};
		this.save = save;
		if (this.direction === "x") {
			Scrollbar.use(InvertDeltaPlugin);

			this.options = {
				...this.options,
				plugins: {
					invertDelta: {
						events: [/wheel/],
					},
				},
			};
		}
		if (delegateGlobal) {
			this.options = {
				...this.options,
				delegateTo: document.querySelector("#root"),
			};
		} else {
			this.options = {
				...this.options,
				delegateTo: this.DOM,
			};
		}

		this.initialPosition = item;

		this.startScroll();
	}

	startScroll() {
		this.sc = Scrollbar.init(this.DOM, this.options);

		if (this.initialPosition && this.initialPosition.el == "home") {
			this.sc.scrollTo(
				this.initialPosition.position.x,
				this.initialPosition.position.y,
				0
			);
			this.dispatch(
				setOffset({
					x: this.initialPosition.position.x,
					y: this.initialPosition.position.y,
				})
			);
		} else {
			this.dispatch(
				setOffset({
					x: 0,
					y: 0,
				})
			);
		}

		this.sc.addListener(this.listener);
	}

	listener(e) {
		this.position = e;
		this.dispatch(setOffset(e.offset));

		this.pourcentageScroll();
		this.isDown(e);
	}

	pourcentageScroll() {
		if (this.roll) {
			this.pour = parseInt(
				(this.position.offset[this.direction] * 100) /
					this.position.limit[this.direction]
			);
			this.dispatch(setPourcentage(this.pour));
		}
	}
	setIsDown(onNext) {
		this.setIsDown = onNext;
	}
	isDown(e) {
		if (this.next) {
			if (e.offset.y == e.limit.y) {
				this.down = true;
				if (this.timerNext) {
					clearTimeout(this.timerNext);
				}
				this.setNext();
			} else {
				this.down = false;
				this.elNext.classList.remove("active");

				if (this.timerNext) {
					clearTimeout(this.timerNext);
				}
			}
			this.downChange();
		}
	}
	downChange() {
		if (this.lastScrollDown != this.down) {
			this.setIsDown(this.down);
			this.lastScrollDown = this.down;
		}
	}
	setIfNext(navigate) {
		const haveNext = this.DOM.querySelector(".next") ? true : false;

		this.next = haveNext;
		this.nextNavigate = haveNext ? navigate : null;
		this.elNext = this.DOM.querySelector(".next");
	}

	setNext() {
		this.elNext.classList.add("active");

		this.timerNext = setTimeout(() => {
			this.doNext(); // A remettre
		}, 4000);
	}

	doNext() {
		this.nextNavigate();
	}

	stop() {
		this.roll = false;
		Scrollbar.destroyAll();
		delete this.roll;
		delete this.position;
		delete this.DOM;
		delete this.direction;
		delete this.dispatch;
		delete this.sc;
	}
}

class InvertDeltaPlugin extends ScrollbarPlugin {
	static pluginName = "invertDelta";

	static defaultOptions = {
		events: [],
	};

	transformDelta(delta, fromEvent) {
		if (this.shouldInvertDelta(fromEvent)) {
			return {
				x: delta.y,
				y: delta.x,
			};
		}

		return delta;
	}

	shouldInvertDelta(fromEvent) {
		return this.options.events.some((rule) => fromEvent.type.match(rule));
	}
}
