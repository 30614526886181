import { Logo } from "components/Logo/Logo";
import s from "./style.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScrollBar } from "components/ScrollBar/ScrollBar";

const base = {
	default: {
		mix: "difference",
		color: "#fff",
		borderColor: "#fff",
	},
	custom: {
		mix: "normal",
	},
};

export function Header(props) {
	const navigate = useNavigate();
	const pourcentage = useSelector((store) => store.HEADER.pourcentage);

	const blend = useSelector((store) => store.HEADER.blend);
	const color = useSelector((store) => store.HEADER.color);

	const [aboutCurrent, setAboutCurrent] = useState(false);
	const [indexCurrent, setIndexCurrent] = useState(false);

	const styleBlend = {
		mixBlendMode: blend ? base.custom.mix : base.default.mix,
	};
	const styleColor = {
		color: blend ? color : base.default.color,
		"::before": {
			backgroundColor: blend ? color : base.default.color,
		},
		fill: blend ? color : base.default.color,
	};

	const style = { ...styleColor, ...styleBlend };

	const location = useLocation();

	useEffect(() => {
		document.documentElement.style.setProperty("--color", color);
	}, [color]);

	useEffect(() => {
		if (location.pathname === "/about") {
			setIndexCurrent(false);
			setAboutCurrent(true);
		} else if (location.pathname === "/index") {
			setIndexCurrent(true);
			setAboutCurrent(false);
		} else {
			setIndexCurrent(false);
			setAboutCurrent(false);
		}
	}, [location.pathname]);

	// if tap on key echap return to home
	useEffect(() => {
		const handleEsc = (event) => {
			const isTransition = document.body.classList.value.includes(
				"transition-progress"
			);

			if (isTransition) return;
			if (event.keyCode === 27) {
				navigate("/");
				document.title =
					"studio kiss - Studio de direction artistique & de création graphique";
			}
		};
		window.addEventListener("keydown", handleEsc);

		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, [navigate]);

	return (
		<>
			<div className={`header ${s.container}`} style={style}>
				<div>
					<Logo
						onClick={(e) => {
							e.preventDefault();
							navigate("/");
							document.title =
								"studio kiss - Studio de direction artistique & de création graphique";
						}}
						title="studio kiss"
						pourcentage={pourcentage}
					/>
				</div>
				<div className={s.menu}>
					<nav>
						<ul className={s.nav}>
							<li
								className={`${s.item}${
									aboutCurrent ? " " + s.current : ""
								}`}
								style={styleColor}
							>
								<a
									style={{ fontWeight: 500 }}
									href="#about"
									onClick={(e) => {
										e.preventDefault();
										navigate("/page/about");
										document.title =
											"À propos — studio kiss - Studio de direction artistique & de création graphique";
									}}
								>
									à propos
								</a>
							</li>
							<li
								className={`${s.item}${
									indexCurrent ? " " + s.current : ""
								}`}
								style={styleColor}
							>
								<a
									style={{ fontWeight: 500 }}
									href="#index"
									onClick={(e) => {
										e.preventDefault();
										navigate("/page/index");
										document.title =
											"Index — studio kiss - Studio de direction artistique & de création graphique";
									}}
								>
									index
								</a>
							</li>

							<li
								className={`${s.item}${
									aboutCurrent ? " " + s.current : ""
								}`}
								style={styleColor}
							>
								<a
									target="_blank"
									href="https://linkedin.com/company/studiokiss-fr"
									title="linkedin"
								>
									linkedin
									{/* <svg
										className={s.icon}
										viewBox="0 0 512 512"
									>
										<path
											style={styleColor}
											d="M471.1 302.5v159h-92.2V313.1c0-37.3-13.3-62.7-46.7-62.7-25.5 0-40.6 17.1-47.3 33.7-2.4 5.9-3.1 14.2-3.1 22.5v154.9h-92.2s1.2-251.3 0-277.3h92.2v39.3l-.6.9h.6v-.9c12.3-18.9 34.1-45.8 83.1-45.8 60.7 0 106.2 39.6 106.2 124.8zm-378-252c-31.5 0-52.2 20.7-52.2 47.9 0 26.6 20 47.9 51 47.9h.6c32.2 0 52.2-21.3 52.2-47.9-.6-27.2-20-47.9-51.6-47.9zm-46.7 411h92.2V184.2H46.4v277.3z"
										/>
									</svg> */}
								</a>

								<a
									target="_blank"
									href="https://www.instagram.com/studiokiss_fr"
									title="instagram"
								>
									instagram
									{/* <svg
										className={s.icon}
										viewBox="0 0 512 512"
									>
										<path
											style={styleColor}
											d="M348.6 9H163.4C78.1 9 9 78.1 9 163.4v185.2C9 433.9 78.1 503 163.4 503h185.2c85.2 0 154.4-69.1 154.4-154.4V163.4C503 78.1 433.9 9 348.6 9zm108.1 339.6c0 59.6-48.5 108.1-108.1 108.1H163.4c-59.6 0-108.1-48.5-108.1-108.1V163.4c0-59.6 48.5-108.1 108.1-108.1h185.2c59.6 0 108.1 48.5 108.1 108.1v185.2z"
										/>
										<path
											style={styleColor}
											d="M256 132.5c-68.2 0-123.5 55.3-123.5 123.5S187.8 379.5 256 379.5 379.5 324.2 379.5 256 324.2 132.5 256 132.5zm0 200.7c-42.5 0-77.2-34.6-77.2-77.2s34.6-77.2 77.2-77.2 77.2 34.6 77.2 77.2c0 42.5-34.7 77.2-77.2 77.2z"
										/>
										<circle
											style={styleColor}
											cx="388.8"
											cy="123.2"
											r="16.5"
										/>
									</svg> */}
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
			<ScrollBar pourcentage={pourcentage} blend={blend} color={color} />
		</>
	);
}
