import { Video } from "components/Video/Video";
import mockupBlack from "./iphone-black.png";
import mockupWhite from "./iphone-white.png";

import iphone from "./iphone.png";
import ui from "./iphone-ui.png";


import locker from "./locker.jpg";
require("./MockupMobile.scss");
export function MockupMobile({ url, color, mime, fakeUrl, contain = false, background = "black" }) {
	return (
		<div className={`mockup-mobile`}>
			<div className="mockup-mobile__container">
				<div className="mockup-mobile__v2">
					<div className="mockup-mobile__coque">
						<img src={iphone} alt="" />

						<svg viewBox="0 0 1056 1080">
							<path
								fill={color ? color : "#fff"}
								d="M788.5 252.9V81c0-41-33.2-74.2-74.2-74.2H341.8c-41 0-74.2 33.2-74.2 74.2v69.3c-2.7 0-4.9 2.2-4.9 4.9v29.7c0 2.7 2.2 4.9 4.9 4.9V227c-2.7 0-4.9 2.2-4.9 4.9v66.8c0 2.7 2.2 4.9 4.9 4.9v19.8c-2.7 0-4.9 2.2-4.9 4.9v66.8c0 2.7 2.2 4.9 4.9 4.9v599c0 41 33.2 74.2 74.2 74.2h372.4c41 0 74.2-33.2 74.2-74.2V374.2c2.7 0 4.9-2.2 4.9-4.9V257.9c.1-2.7-2.1-5-4.8-5zM760 931.5H296V93.2h464v838.3z"
							/>
						</svg>
					</div>
					<div className="mockup-mobile__ui">
						<img src={ui} alt="" />
					</div>
				</div>

				<div className="mockup-mobile__fakeurl">
					<div className="mockup-mobile__fakeurl__background"></div>
					<img src={locker} alt="locker" />
					{fakeUrl}
				</div>
				<div className="mockup-mobile__video" style={{background:background }}>
					<Video url={url} mime={mime} contain={contain} />
				</div>
			</div>
		</div>
	);
}
