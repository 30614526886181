import s from "./style.module.scss";
import { ProjectComponents } from "components/ProjectComponents/ProjectComponents";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { setBlend, setColor } from "store/header/header-slice";
import { Scroller } from "components/Scroller/Scroller";
import PageAnimation from "components/pageAnimation/pageAnimation";

import { LoadNextProject } from "components/LoadNextProject/LoadNextProject";

export function Project(props) {
	const [projectColor, setProjectColor] = useState({
		color: "#fff",
		backgroundColor: "#000",
	});

	const [isBlend, setisBlend] = useState(false);

	const dispatch = useDispatch();
	const { projectSlug } = useParams();
	const project = useSelector((store) =>
		store.PROJECT.projectList.find(
			(project) => project?.attributes?.slug === projectSlug
		)
	);

	const nextEl = useRef();

	const navigate = useNavigate();
	const [isDown, setisDown] = useState(false);

	const setColorProject = () => {
		if (project) {
			if (project?.attributes?.text_color) {
				dispatch(setBlend(true));
				setisBlend(true);
				dispatch(setColor(project?.attributes?.text_color));
			} else {
				dispatch(setBlend(false));
				setisBlend(false);
			}

			setProjectColor({
				color: project?.attributes?.text_color
					? project?.attributes?.text_color
					: projectColor.color,
				backgroundColor: project?.attributes?.background_color,
				borderColor: project?.attributes?.text_color
					? project?.attributes?.text_color
					: projectColor.color,
				"--backgroundColor":
					project?.attributes?.background_color ??
					projectColor.backgroundColor,
				"--borderColor": project?.attributes?.text_color
					? project?.attributes?.text_color
					: projectColor.color,
				"--color": project?.attributes?.text_color
					? project?.attributes?.text_color
					: projectColor.color,
				"--blend": isBlend ? "difference" : "none",
			});
		}
	};

	useEffect(() => {
		setColorProject(project);
		document.title = project
			? project.attributes.Title +
			  " — studio kiss - Studio de direction artistique & de création graphique"
			: "studio kiss - Studio de direction artistique & de création graphique";
	}, [dispatch, project]);

	// const

	const haveNextProject = () => {
		if (
			project?.attributes?.next?.data?.attributes?.Thumbnail.data &&
			project?.attributes?.next?.data?.attributes?.Contents.length > 0
		) {
			return true;
		} else {
			return false;
		}
	};

	const allProjectInHome = useSelector((store) => store.PROJECT.projectList);
	const indexProject = allProjectInHome.findIndex(
		(project) => project?.attributes?.slug === projectSlug
	);

	// get next project
	const nextProjectIndex = (indexProject + 1) % allProjectInHome.length;
	const nextProject =
		allProjectInHome.length > 0
			? haveNextProject()
				? project?.attributes?.next?.data?.attributes
				: nextProjectIndex === 0
				? allProjectInHome[0].attributes
				: allProjectInHome[nextProjectIndex].attributes
			: null;

	// FORCE NEXT PROJECT in HOME PAGE
	// const nextProject =
	// 	allProject.length > 0
	// 		? nextProjectIndex === 0
	// 			? allProject[0].attributes
	// 			: allProject[nextProjectIndex].attributes
	// 		: null;

	const navigateNextProject = () => {
		navigate("/projet/" + nextProject?.slug);
	};
	const [isScrollerRef, setisScrollerRef] = useState(false);
	// useEffect(() => {
	// 	const isMobile = window.innerWidth < 768;
	// 	if(isMobile){
	// 		const scrollContainer = document.querySelector(".scroller-container");
	// 		if(!scrollContainer) return;

	// 		console.log(scrollContainer)

	// 		const nextEl = document.querySelector(".next");
	// 		scrollContainer.addEventListener("scroll", (e) => {
	// 			console.log(e)
	// 			if (nextEl) {

	// 				// console.log(scrollContainer.scrollTop)
	// 				// const nextElTop = nextEl.getBoundingClientRect().top;
	// 				// const nextElHeight = nextEl.getBoundingClientRect().height;
	// 				// const scrollContainerHeight = scrollContainer.getBoundingClientRect().height;
	// 				// const scrollContainerTop = scrollContainer.getBoundingClientRect().top;
	// 				// const scrollContainerBottom = scrollContainer.getBoundingClientRect().bottom;
	// 				// const scrollContainerScrollTop = scrollContainer.scrollTop;
	// 				// const scrollContainerScrollBottom = scrollContainerScrollTop + scrollContainerHeight;

	// 				// if (
	// 				// 	scrollContainerScrollBottom >=
	// 				// 		nextElTop + nextElHeight &&
	// 				// 	scrollContainerScrollTop <=
	// 				// 		nextElTop + nextElHeight &&
	// 				// 	scrollContainerTop <= 0
	// 				// ) {
	// 				// 	setisDown(true);
	// 				// } else {
	// 				// 	setisDown(false);
	// 				// }
	// 			}
	// 		});
	// 	}

	// }, [document.querySelector(".scroller-container")]);

	if (project) {
		return (
			<PageAnimation>
				<Scroller
					save={true}
					slug={projectSlug}
					next={() => navigateNextProject()}
					onNext={(bool) => {
						setisDown(bool);
					}}
				>
					<div
						style={project && projectColor}
						className={`${s.container}`}
					>
						{project?.attributes?.Contents.map((content, index) => (
							<ProjectComponents
								content={content}
								key={index}
								index={index}
								type={content.__component}
								project={project}
							/>
						))}

						<section
							ref={nextEl}
							className={`next ${s.nextMobile}`}
						>
							<div>
								Projet suivant {project && nextProject?.Title}
							</div>

							<LoadNextProject
								controls={isDown}
								projectColor={projectColor}
							/>
						</section>
					</div>
				</Scroller>
			</PageAnimation>
		);
	}
}
