import { createSlice } from "@reduxjs/toolkit";

export const othersSlice = createSlice({
	name: "othersSlice",
	initialState: {
		data: {},
	},
	reducers: {
		setDataOthers: (currentSlice, action) => {
			currentSlice.data = action.payload;
		},
	},
});

export const othersReducer = othersSlice.reducer;
export const { setDataOthers } = othersSlice.actions;
