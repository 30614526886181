import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { BigTitleAPI } from "api/bigtitle-api";
import { ProjectAPI } from "api/project-api";
import { PagesAPI } from "api/other-pages-api";

import { setDataAbout } from "store/about/about-slice";
import { setDataIndex } from "store/index/index-slice";
import { setText } from "store/bigtitle/bigtitle-slice";
import { setProjectList } from "store/project/project-slice";
import { setClientList } from "store/client/client-slice";
import { setSecteurList } from "store/secteur/secteur-slice";

import { Loader } from "components/Loader/Loader";
import { Header } from "components/Header/Header";
import { setDataOthers } from "store/others/others-slice";

export function App() {
	const isProd = process.env.NODE_ENV === "production";

	const dispatch = useDispatch();
	useEffect(() => {
		const fetchHomeProjects = async () => {
			const ProjectList = await ProjectAPI.fetchHome();
			dispatch(setProjectList(ProjectList));
		};
		const fetchHomeBigTitle = async () => {
			const BigTitleContent = await BigTitleAPI.fetch();
			dispatch(setText(BigTitleContent));
		};
		const fetchAbout = async () => {
			const AboutData = await ProjectAPI.fetchAbout();
			dispatch(setDataAbout(AboutData.attributes.Sections));
		};
		const fetchIndex = async () => {
			const IndexData = await ProjectAPI.fetchIndex();
			dispatch(setDataIndex(IndexData.attributes.projects.data));
		};

		const fetchClients = async () => {
			const ClientsData = await ProjectAPI.fetchClients();
			dispatch(setClientList(ClientsData.data));
		};

		const fetchSecteurs = async () => {
			const SecteursData = await ProjectAPI.fetchSecteurs();
			dispatch(setSecteurList(SecteursData.data));
		};
		const fetchUploads = async () => {
			const UploadsData = await ProjectAPI.fetchUploads();
			// dispatch(setDataOthers(UploadsData));
		};

		const fetchPages = async () => {
			const OthersData = await PagesAPI.fetchPages();
			dispatch(setDataOthers(OthersData));
		};

		fetchHomeProjects();
		fetchHomeBigTitle();
		fetchAbout();
		fetchIndex();
		fetchClients();
		fetchSecteurs();
		fetchPages();
		fetchUploads();
	}, [dispatch]);

	const navigate = useNavigate();

	return (
		<>
			<React.StrictMode>
				{isProd && <Loader />}
				<Header />
			</React.StrictMode>
		</>
	);
}
