import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
	name: "loaderSlice",
	initialState: {
		visibility: true,
	},
	reducers: {
		setVisibility: (currentSlice, action) => {
			currentSlice.visibility = action.payload;
		},
	},
});

export const loaderReducer = loaderSlice.reducer;
export const { setVisibility } = loaderSlice.actions;
