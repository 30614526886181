import { createSlice } from "@reduxjs/toolkit";

export const projectSlice = createSlice({
  name: "projectSlice",
  initialState: {
    projectList: [],
  },
  reducers: {
    setProjectList: (currentSlice, action) => {
      currentSlice.projectList = action.payload;
    },
  },
});

export const projectReducer = projectSlice.reducer;
export const { setProjectList } = projectSlice.actions;
