import { useEffect, useState } from "react";
import s from "./style.module.css";
export function LoadNextProject({ controls, projectColor }) {
	const [isStart, setIsStart] = useState(false);

	useEffect(() => {
		setIsStart(controls);
	}, [controls]);

	return (
		<div className={`${window.width > 768 ? s.container : s.containerMobile}`}>
			<span
				className={`${s.progress} ${isStart && s.progressActive}`}
			></span>
		</div>
	);
}
