import s from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setBlend, setColor } from "store/header/header-slice";
import { ProjectLine } from "components/ProjectLine/ProjectLine";
import { Scroller } from "components/Scroller/Scroller";
import { indexClass } from "./indexClass";
import { PageIndexThumbs } from "./PageIndexThumbs";
import PageAnimation from "components/pageAnimation/pageAnimation";

export function PageIndex(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [positionTHUMBS, setPositionTHUMBS] = useState({ x: 0, y: 0 });

	const container = useRef();
	const projectListTemp = useSelector((store) => store.INDEX.data);
	const allProjectInHome = useSelector((store) => store.PROJECT.projectList);

	let projectList = [];
	if (projectListTemp.length > 0) {
		projectList = projectListTemp;
	}

	useEffect(() => {
		dispatch(setBlend(true));
		dispatch(setColor("#000"));
	}, [dispatch]);

	useEffect(() => {
		let indexScript = null;
		if (projectList.length > 0) {
			indexScript = new indexClass();
			indexScript.init(container.current, setPositionTHUMBS);
		}

		return () => {
			if (indexScript) {
				indexScript.stop();
			}
		};
	}, [projectList]);

	const isInHome = (slug) => {
		const home = allProjectInHome.find(
			(project) => project.attributes.slug === slug
		);
		return home ? true : false;
	};

	const offset = useSelector((store) => store.SCROLLER.offset);

	useEffect(() => {
		container.current.style.setProperty("--x", `${positionTHUMBS.x}px`);
		if (offset) {
			container.current.style.setProperty(
				"--y",
				`${positionTHUMBS.y + offset.y}px`
			);
		} else {
			container.current.style.setProperty("--y", `${positionTHUMBS.y}px`);
		}
	}, [positionTHUMBS, offset]);

	return (
		<PageAnimation>
			<Scroller save={true} slug={"index"}>
				<div ref={container} className={`index ${s.container}`}>
					<div className={s.wrapper}>
						<div className={s.list}>
							{projectList.length > 0 &&
								projectList.map((project, index) => (
									<div key={index} className={`project`}>
										<ProjectLine
											key={index}
											title={project.attributes.Title}
											categories={
												project.attributes.categories
													.data
											}
											onClickNavigate={() => {
												if (
													isInHome(
														project.attributes.slug
													)
												) {
													navigate(
														"/projet/" +
															project.attributes
																.slug
													);
												} else if (
													project.attributes?.url
												) {
													window.open(
														project.attributes?.url,
														"_blank"
													);
												}
											}}
											pointer={
												isInHome(
													project.attributes.slug
												) || project.attributes?.url
											}
										/>
									</div>
								))}
						</div>
						<div className={`thumbs ${s.thumbsContainer}`}>
							{projectList.length > 0 &&
								projectList.map((project, index) => (
									<PageIndexThumbs
										project={project}
										key={index}
										index={index}
									/>
								))}
						</div>
					</div>
				</div>
			</Scroller>
		</PageAnimation>
	);
}
