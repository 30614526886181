import { createSlice } from "@reduxjs/toolkit";

export const aboutSlice = createSlice({
	name: "aboutSlice",
	initialState: {
		data: {},
	},
	reducers: {
		setDataAbout: (currentSlice, action) => {
			currentSlice.data = action.payload;
		},
	},
});

export const aboutReducer = aboutSlice.reducer;
export const { setDataAbout } = aboutSlice.actions;
