import { Categories } from "components/Categories/Categories";
import s from "./style.module.scss";
import { CategoriesIcons } from "components/CategoriesIcons/CategoriesIcons";

export function ProjectLine({
	title,
	categories,
	onClickNavigate,
	on,
	pointer = true,
}) {
	return (
		<div
			className={`${s.item}`}
			style={{
				cursor: pointer ? "pointer" : "default",
			}}
			onClick={onClickNavigate}
		>
			<div className={s.wrapper}>
				<div className={s.title}>
					<div className="card-title">{title}</div>
				</div>
				<div className={s.categories}>
					<Categories
						categoriesList={categories}
						styleItem={"index"}
					/>
				</div>
			</div>
		</div>
	);
}
