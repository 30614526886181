import s from "./style.module.scss";

export function Category({ title, styleItem = undefined }) {

	const styles = (type) =>{
		if(!type){
			return s.default;
		}
		else if(type === 'index'){
			return s.index;
		}
		else if(type === 'project'){
			return s.project
		}
	}

	return (
		<>
			<div className={styles(styleItem)}>
				{title.toUpperCase()}
			</div>
		</>
	);
}
