import s from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
export function Loader() {
	const [isVisible, setIsVisible] = useState(true);
	const [isDOM, setIsDOM] = useState();
	const [isSVG, setIsSVG] = useState();
    const DOM = useRef();
    const svg = useRef();

	useEffect(() => {
        if(isDOM){
            anim();
        }
	}, [isDOM]);

	const anim = () => {
		const allLetters = [...isSVG.querySelectorAll("path")];

		const n1 = allLetters.slice(0, 4);
		const n2 = allLetters.slice(4, 6);
		const n3 = allLetters.slice(6, 14);
		const n4 = allLetters.slice(14, 20);

		const tl = gsap.timeline();

		tl.to(n1, {
			duration: 0.5,
		});

		tl.to(n1, {
			duration: 0.5,
			y: 0,
			x: 0,
			opacity: 1,
			ease: "power4.out",
			stagger: {
				amount: 0.1,
			},
		});

		tl.to(n2, {
			duration: 0.5,
			y: 0,
			x: 0,
			opacity: 1,
			ease: "power4.out",
			stagger: {
				amount: 0.02,
			},
		});

		tl.to(
			n3,
			{
				duration: 0.5,
				y: 0,
				x: 0,
				opacity: 1,
				ease: "power4.out",
				stagger: {
					amount: 0.1,
				},
			},
			"-=0.3"
		);

		tl.to(n4, {
			duration: 0.5,
			y: 0,
			x: 0,
			opacity: 1,
			ease: "power4.out",
			stagger: {
				amount: 0.1,
			},
		});

		tl.to(allLetters, {
			duration: 1,
		});

		tl.to(allLetters, {
			duration: 0.5,
			opacity: 0,
			ease: "power4.in",
			stagger: {
				amount: 0.2,
			},
		});

		tl.to(
			isDOM,
			{
				duration: 0.5,
				autoAlpha: 0,
				ease: "power4.in",
			},
			"-=0.2"
		);

        tl.add(()=>{
            setIsVisible(false)
        })

	};

	if (isVisible) {
		return (
			<div ref={DOM => { setIsDOM(DOM); }} className={s.container}>
				<div className={s.background}></div>
				<svg ref={SVG => { setIsSVG(SVG); }} className={s.svg} viewBox="0 0 241.1 97.3">
					<path d="M2 .7h8.6v14.9L19.3.7h9.5L21 13.9l8.6 15.4h-10l-6.2-11.8h-2.7v11.8H2V.7z" />
					<path d="M52 .7v7.9H40.4v3.1h10.3v6.6H40.4v3.1H52v7.9H31.8V.7H52z" />
					<path d="M76.4.7v7.9H64.8v3.1h10.3v6.6H64.8v3.1h11.6v7.9H56.2V.7h20.2z" />
					<path d="M94.9.7C101 .7 105 5 105 10.9c0 6-4 10.3-10.1 10.3h-5.7v8h-8.6V.7h14.3zm-1.5 7.8h-4.2v5h4.2c2 0 2.9-1.1 2.9-2.5 0-1.5-.9-2.5-2.9-2.5z" />
					<path d="M10.6 34v28.5H2V34h8.6z" />
					<path d="M13.5 41.8V34H37v7.7h-7.5v20.8H21V41.8h-7.5z" />
					<path d="M52 52c.8 2.2 2.7 3.6 5.1 3.6 1.9 0 2.9-.6 2.9-1.8 0-1.1-1.1-1.6-3.5-2.3l-3.2-.9c-4.5-1.3-7-4.2-7-8.6-.1-5.4 5.2-8.9 10.8-8.8 2.2 0 4.2.5 6.1 1.5s3.3 2.6 4 4.6l-6.6 3.2c-.6-1.4-1.6-2.1-3.2-2.1-1.3 0-2.3.4-2.3 1.4 0 1 1.1 1.5 3.2 2.1l3.1.9c5 1.4 7.5 4.3 7.5 8.6 0 6.1-5.3 9.8-11.6 9.8-2.9 0-5.4-.7-7.7-2-2.2-1.4-3.7-3.3-4.5-5.7L52 52z" />
					<path d="M69.5 41.8V34H93v7.7h-7.5v20.8H77V41.8h-7.5z" />
					<path d="M120.9 62.5h-9.8l-6.6-11.7v11.7H96V34h14.4c5.5 0 9.8 4 9.8 9.5 0 4-2.2 6.9-5.7 8.1l6.4 10.9zm-16.4-16.1h3.9c2.1 0 3-1.1 3-2.6s-.9-2.6-3-2.6h-3.9v5.2z" />
					<path d="M132.5 34v28.5H124V34h8.5z" />
					<path d="M151.4 33.3c6.1 0 11 2.9 13.6 7.7l-7.8 3.7c-1.2-2.3-3.1-3.4-5.8-3.4-4.2 0-6.6 3.3-6.6 6.9 0 3.7 2.4 6.9 6.6 6.9 2.6 0 4.6-1.1 5.8-3.4l7.8 3.7c-2.6 4.8-7.4 7.7-13.6 7.7-8.8.2-15.4-6.7-15.3-15-.2-8.1 6.4-15 15.3-14.8z" />
					<path d="M167.9 41.8V34h23.5v7.7h-7.5v20.8h-8.6V41.8h-7.4z" />
					<path d="M194.3 34h8.6v20.6h11v7.9h-19.5V34z" />
					<path d="M210.7 34h9.8l5.2 11 5.2-11h9.8L230 54.3v8.3h-8.6v-8.3L210.7 34z" />
					<path d="M7.7 86c.8 2.2 2.7 3.6 5.1 3.6 1.9 0 2.9-.6 2.9-1.8 0-1.1-1.1-1.6-3.5-2.3L9 84.6c-4.5-1.3-7-4.2-7-8.6-.1-5.4 5.2-8.9 10.8-8.8 2.2 0 4.2.5 6.1 1.5s3.3 2.6 3.9 4.6l-6.6 3.2c-.6-1.4-1.6-2.1-3.2-2.1-1.3 0-2.3.4-2.3 1.4 0 1 1.1 1.5 3.2 2.1l3.1.9c5 1.4 7.5 4.3 7.5 8.6 0 6.1-5.3 9.8-11.6 9.8-2.8 0-5.4-.7-7.7-2C3 93.8 1.5 91.9.7 89.5l7-3.5z" />
					<path d="M36.3 68.1v28.5h-8.6V68.1h8.6z" />
					<path d="M41.1 68.1H48l8.4 9.5 8.4-9.5h6.9v28.5h-8.6V82.1l-6.8 7-6.8-7v14.4h-8.6V68.1z" />
					<path d="M90.7 68.1c6.1 0 10.1 4.3 10.1 10.2 0 6-4 10.3-10.1 10.3H85v8h-8.6V68.1h14.3zm-1.5 7.7H85v5h4.2c2 0 2.9-1.1 2.9-2.5s-.9-2.5-2.9-2.5z" />
					<path d="M103.9 68.1h8.6v20.6h11v7.9H104V68.1z" />
					<path d="M146.6 68.1V76H135v3h10.3v6.6H135v3.1h11.6v7.9h-20.2V68.1h20.2z" />
				</svg>
			</div>
		);
	}
}
