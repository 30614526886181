import "./utils";

import "./assets/css/easing.css";
import "./assets/css/fonts.css";
import "./assets/css/reset.css";

import "./index.scss";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { PageNotFound } from "pages/PageNotFound/PageNotFound";
import { App } from "App";

import { Project } from "pages/Project/Project";
import { ProjectBrowse } from "pages/ProjectBrowse/ProjectBrowse";
import { Page } from "pages/Page/Page";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
			<AnimatedRoutes />
		</BrowserRouter>
	</Provider>
);

function AnimatedRoutes() {
	const location = useLocation();

	return (
		<AnimatePresence>
			<Routes key={location.pathname} location={location}>
				<Route path="/" element={<ProjectBrowse />} />
				<Route path="/projet/:projectSlug" element={<Project />} />
				<Route path="/page/:slug" element={<Page />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</AnimatePresence>
	);
}
