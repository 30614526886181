export class indexClass {
	constructor() {
		this.move = this.move.bind(this);
		this.getMouse = this.getMouse.bind(this);
		this.prepareHover = this.prepareHover.bind(this);
		this.hover = this.hover.bind(this);
		this.moveThumbContainer = this.moveThumbContainer.bind(this);
	}
	init(el, setPositionTHUMBS) {
		this.roll = true;
		this.DOM = el;
		this.THUMBS = el.querySelector(".thumbs");
		this.prepareHover();
		this.getMouse();
	}

	getMouse() {
		this.mouseX = window.innerWidth / 2;
		this.mouseY = window.innerHeight / 2;
		const that = this;
		this.circle = {
			el: this.THUMBS,
			x: window.innerWidth / 2,
			y: window.innerHeight / 2,
			w: this.THUMBS.offsetWidth,
			h: this.THUMBS.offsetHeight,
			update: function () {
				const l = this.x - this.w / 2;
				const t = this.y - this.h / 2;
				requestAnimationFrame(that.move);
			},
		};

		this.DOM.ownerDocument.defaultView.addEventListener(
			"mousemove",
			(e) => {
				this.mouseX = e.clientX;
				this.mouseY = e.clientY;
				this.target = e.target;
			}
		);
		if (this.roll) {
			requestAnimationFrame(this.move);
		}
	}

	move() {

		const hWin = window.innerHeight;
		const wWin = window.innerWidth;

		if(this.circle.x > wWin - this.circle.w){
			this.circle.x = this.lerp(wWin - this.circle.w, this.mouseX, 0.05);
		}else{
			this.circle.x = this.lerp(this.circle.x, this.mouseX, 0.05);
		}

		if(this.circle.y > hWin - this.circle.h){
			this.circle.y = this.lerp(hWin - this.circle.h, this.mouseY, 0.05);
		}else{
			this.circle.y = this.lerp(this.circle.y, this.mouseY, 0.05);
		}


		if (this.roll) {
			this.circle.update();
			this.hover();
			this.moveThumbContainer();
		} else {
			this.stop();
		}
	}

	lerp(start, end, amt) {
		return (1 - amt) * start + amt * end;
	}

	prepareHover() {
		this.thumbsElement = [...this.DOM.querySelectorAll(".thumbs .thumb")];
		this.projectsElement = [
			...this.DOM.querySelectorAll(".index .project"),
		];
	}

	moveThumbContainer() {
		if (this.roll) {
			let top = 0;
			if (document.querySelector(".scroll-content")) {
				top =
					parseInt(
						getTranslateY(document.querySelector(".scroll-content"))
					) * -1;
			}
			top = this.circle.y + top;
			this.THUMBS.style.setProperty("--x", `${this.circle.x}px`);
			this.THUMBS.style.setProperty("--y", `${top}px`);
		}
	}

	hover() {
		if (this.roll) {
			if (this.lastNode && this.target) {
				if (!this.target.parentNode?.classList) {
					return null;
				}
				if (
					!this.lastNode.isSameNode(this.target) &&
					[...this.target.parentNode.classList].includes("project")
				) {
					const project = this.target.parentNode;
					const index = Array.from(
						project.parentNode.children
					).indexOf(project);
					const thumb = this.thumbsElement[index];
					this.hovered(project, thumb);
					this.lastNode = this.target;
				}
			} else {
				this.lastNode = this.target;
			}
		}
	}
	hovered(el, thumb) {
		this.projectsElement.forEach((project) => {
			project.style.cssText = "opacity: 0.1";
		});
		el.style.cssText = "opacity: 1";

		this.thumbsElement.forEach((thumb) => {
			thumb.style.cssText = "z-index: 1;";
			thumb.querySelector("img").style.cssText =
				"opacity:0; transform:scale(1.1)";
		});
		thumb.style.cssText = "opacity: 1;transform: scale(1);z-index: 2;";
		thumb.querySelector("img").style.cssText =
			"opacity:1; transform:scale(1)";
	}
	stop() {
		this.roll = false;
	}
}

function getTranslateY(myElement) {
	return window
		.getComputedStyle(myElement)
		.transform.replace("matrix(1, 0, 0, 1, 0, ", "")
		.replace(")", "");
}
