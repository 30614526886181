import { createSlice } from "@reduxjs/toolkit";

export const secteurSlice = createSlice({
	name: "secteurSlice",
	initialState: {
		secteurList: [],
	},
	reducers: {
		setSecteurList: (currentSlice, action) => {
			currentSlice.secteurList = action.payload;
		},
	},
});

export const secteurReducer = secteurSlice.reducer;
export const { setSecteurList } = secteurSlice.actions;
