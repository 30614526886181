import { useEffect, useRef, useState } from "react";
import s from "./style.module.css";

const urlImage = (url) => {
	if (
		process.env.REACT_APP_NODE_ENV === "development" ||
		process.env.REACT_APP_NODE_ENV === "staging"
	) {
		return process.env.REACT_APP_BASE_URL + url + "?format=webp";
	} else {
		const webpUrl = url.replace(/\.(png|jpg)/gi, ".webp");
		return process.env.REACT_APP_BASE_URL + webpUrl;
	}
};
export function ProgressiveImage({
	fullSrc,
	isThumbLoadedFinish = () => {},
	width,
	height,
	alt,
	contain = true,
	srcSet,
	max = "large",
}) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [usedSrc, setUsedSrc] = useState();

	const fileName = fullSrc ? fullSrc.split("/").pop().split(".")[0] : "";

	let srcsetPonderation = {
		large: 1,
		medium: 0.75,
		small: 0.5,
		thumbnail: 0.25,
	};

	// remove pondération supérieur au max
	Object.keys(srcsetPonderation).forEach((key) => {
		if (key !== max) {
			if (srcsetPonderation[key] > srcsetPonderation[max]) {
				delete srcsetPonderation[key];
			}
		}
	});
	// remove key in srset that are not in srcsetPonderation
	let srcSetTemp = { ...srcSet };
	if (srcSet) {
		Object.keys(srcSet).forEach((key) => {
			if (!srcsetPonderation[key]) {
				delete srcSetTemp[key];
			}
		});
	}

	// get the best srcSet for the image
	const srcSetValuesPonderated = srcSetTemp
		? Object.keys(srcSetTemp).map((key) => {
				const url = srcSetTemp[key].url;
				const width = srcSetTemp[key].width;
				const ponderation = srcsetPonderation[key];
				return { url, width, ponderation };
		  })
		: null;

	// TODO : Faire les webp
	useEffect(() => {
		if(isLoaded) return;
		if (fullSrc === undefined || fullSrc === null) return;

		const img = new Image();
		img.src = urlImage(srcSetValuesPonderated[0].url);

		img.onload = () => {
			setUsedSrc(img.src);
			setIsLoaded(true);
			isThumbLoadedFinish(true);
		};
		
	}, [fullSrc, isThumbLoadedFinish]);

	const noSrc = usedSrc ? true : false;

	// create srcSet string with srcSet object from strapi
	const srcSetString = srcSetTemp
		? Object.keys(srcSetTemp)
				.map((key) => {
					const url = srcSetTemp[key].url;
					const width = srcSetTemp[key].width;
					return `${urlImage(url)} ${width}w`;
				})
				.join(", ")
		: null;
	return (
		<img
			className={`${s.item} ${isLoaded ? s.loaded : ""} ${
				contain ? s.contain : s.cover
			} ${noSrc ? "" : s.hidden} ${s.hidden}`}
			// src={usedSrc}
			width={width}
			height={height}
			alt={alt ? alt : fileName}
			loading="lazy"
			ref={(el) => {
				if (el) {
					const observer = new IntersectionObserver(
						(entries) => {
							entries.forEach((entry) => {
								if (entry.isIntersecting) {
									if (
										el.parentNode.parentNode.parentNode
											.firstElementChild ===
										el.parentNode.parentNode
									) {
										el.classList.remove(s.hidden);
									}

									el.classList.remove(s.hidden);
									el.classList.add(s.visible);
								}
							});
						},
						{
							threshold: 0.1,
						}
					);
					observer.observe(el);
				}
			}}
			srcSet={srcSetString}
		/>
	);
}
