import s from "./style.module.scss";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import React, { useEffect } from "react";
let indexRender = 0;
export function AboutComponents({ type, content, keySectionParent }) {
	const Contents = [];
	const sectionClient = useSelector((store) => store.CLIENT);
	const sectionSecteur = useSelector((store) => store.SECTEUR);

	const AllClients = sectionClient.clientList.length;
	const PourcentageSecteurClients = (x) => {
		return parseInt((x / AllClients) * 100) + "%";
	};

	indexRender++;
	const key = indexRender;

	switch (type) {
		case "about.adresses":
			Contents.push(
				<div key={key} className={s.container}>
					<ul className={s.columns}>
						<li>
							<MarkDownBreakLine>
								{content.Adresse1}
							</MarkDownBreakLine>
						</li>
						<li>
							<MarkDownBreakLine>
								{content.Adresse2}
							</MarkDownBreakLine>
						</li>
					</ul>
				</div>
			);

			break;
		case "about.texte-d-introduction":
			Contents.push(
				<div key={key} className={s.introduction}>
					<MarkDownBreakLine>
						{content.Introduction}
					</MarkDownBreakLine>
				</div>
			);
			break;
		case "about.description":
			Contents.push(
				<div key={key} className={`${s.description} ${s.container}`}>
					<MarkDownBreakLine>{content.Description}</MarkDownBreakLine>
				</div>
			);
			break;
		case "about.clients":
			Contents.push(
				<div key={key} className={s.container}>
					<Title>Clients</Title>
					<div className={s.columns}>
						{sectionClient &&
							sectionClient.clientList.map((client, index) => (
								<p key={index}>{client.attributes.Name}</p>
							))}
					</div>
				</div>
			);

			break;
		case "about.secteurs":
			Contents.push(
				<div key={key} className={s.container}>
					<Title>Secteur D'activités</Title>
					<div className={s.columns}>
						{sectionSecteur &&
							sectionSecteur?.secteurList.map(
								(secteur, index) => (
									<p key={index}>
										{secteur.attributes.Type}
										<span className={s.pourcentageSecteurs}>
											{PourcentageSecteurClients(
												secteur?.attributes.clients
													?.data?.length
											)}
										</span>
									</p>
								)
							)}
					</div>
				</div>
			);

			break;

		case "about.competences":
			Contents.push(
				<div key={key} className={`${s.competences} ${s.container}`}>
					<Title>Compétences</Title>
					<MarkDownBreakLine>{content.Competences}</MarkDownBreakLine>
				</div>
			);
			break;

		default:
			<div key={key} className={`${s.description} ${s.container}`}>
				<MarkDownBreakLine>{content.Description}</MarkDownBreakLine>
			</div>;
	}

	return Contents;
}

function Title(props) {
	return <h2 className={s.title}>{props.children}</h2>;
}

function MarkDownBreakLine(props) {
	const content = (
		<ReactMarkdown
			remarkPlugins={[remarkBreaks]}
			children={props.children.replace(/\n/gi, "&nbsp; \n")}
		/>
	);

	return content;
}

// function AddNavigate({ props }, { navigate }) {
// 	const regex = /\[(.*?)\]\((.*?)\)/g;

// 	const baliseA = (match, $1, $2) => {
// 		return (
// 			<a
// 				href={$2}
// 				onClick={(e) => {
// 					e.preventDefault();
// 					navigate($2);
// 					document.title = `${$1} — studio kiss - Studio de direction artistique & de création graphique`;
// 				}}
// 			>
// 				{$1}
// 			</a>
// 		);
// 	};

// 	const children = React.Children.map(props.children, (child) => {
// 		if (React.isValidElement(child)) {
// 			const { children: childChildren } = child.props;
// 			let result = childChildren;
// 			if (typeof childChildren === "string") {
// 				// result = childChildren.replace(regex, baliseA);
// 			}
// 			return React.cloneElement(child, { children: result });
// 		}
// 		return child;
// 	});

// 	return <>{children}</>;
// }
// function AddNavigate2({ content }, { navigate }) {
// 	const regex = /\[(.*?)\]\((.*?)\)/g;

// 	const baliseA = (match, $1, $2) => {
// 		return (
// 			<a
// 				href={$2}
// 				onClick={(e) => {
// 					e.preventDefault();
// 					navigate($2);
// 					document.title = `${$1} — studio kiss - Studio de direction artistique & de création graphique`;
// 				}}
// 			>
// 				{$1}
// 			</a>
// 		);
// 	};
// 	const string = (match, $0, $1) => {
// 		const nav = navigate;
// 		return `<a href="${$1}" onClick="event.preventDefault();">${$0}</a>`;
// 	};

// 	const result = content.replace(regex, string);

// 	return <div dangerouslySetInnerHTML={{ __html: result }} />;

// 	return <>{result}</>;
// }
