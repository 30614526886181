import { useEffect, useRef } from "react";
import s from "./style.module.css";

import { Scroll } from "./ScrollerClass";
import { useDispatch, useSelector } from "react-redux";
import { addElement } from "store/scroller/scroller-slice";
export function Scroller({
	direction = "y",
	overflow = true,
	delegateGlobal,
	save = false,
	slug = "",
	...props
}) {
	const container = useRef();
	const dispatch = useDispatch();

	const elementsPositions = useSelector((store) => store.SCROLLER.elements);
	const offset = useSelector((store) => store.SCROLLER.offset);

	let scroll = null;
	const isMobile = window.innerWidth < 768;
	useEffect(() => {
		props.ref = container.current;

		if (isMobile) {
			const nextEl = document.querySelector(".next");
			props.ref.addEventListener("scroll", (e) => {
				if (nextEl) {
					const scrollContainer = props.ref;
					const nextElTop = nextEl.getBoundingClientRect().top;
					const nextElHeight = nextEl.getBoundingClientRect().height;
					const scrollContainerHeight =
						scrollContainer.getBoundingClientRect().height;
					const scrollContainerTop =
						scrollContainer.getBoundingClientRect().top;
					const scrollContainerBottom =
						scrollContainer.getBoundingClientRect().bottom;
					const scrollContainerScrollTop = scrollContainer.scrollTop;
					const scrollContainerScrollBottom =
						scrollContainerScrollTop + scrollContainerHeight;
					if (
						scrollContainerScrollBottom >=
							nextElTop + nextElHeight &&
						scrollContainerScrollTop <= nextElTop + nextElHeight &&
						scrollContainerTop <= 0
					) {
						// setisDown(true);
						// console.log("down")
					} else {
						// console.log("up")
						// setisDown(false);
					}
				}
			});

			return;
		}

		const item = elementsPositions.find((item) => item.el === slug);

		scroll = new Scroll();
		if (slug == "home") {
			scroll.init(
				container.current,
				direction,
				dispatch,
				delegateGlobal,
				item,
				save
			);
		}
		setTimeout(() => {
			scroll.init(
				container.current,
				direction,
				dispatch,
				delegateGlobal,
				item,
				save
			);
			if (props.next) {
				scroll.setIfNext(props.next);
				scroll.setIsDown(props.onNext);
			}
		}, 950);
		return () => {
			if (save) {
				dispatch(
					addElement({
						el: slug,
						position: scroll.sc.offset,
					})
				);
			}
			scroll.stop();
		};
	}, []);

	useEffect(() => {
		const links = [
			...container.current.querySelectorAll(".project-description a"),
		];

		links.map((link) => {
			link.setAttribute("target", "_blank");
		});
	}, []);

	return (
		<div
			ref={container}
			className={`scroller-container ${
				direction === "y" ? s.directionY : s.directionX
			} ${overflow ? s.overflow : ""} ${s.container} ${
				isMobile ? s.mobile : ""
			}`}
		>
			{props.children}
		</div>
	);
}
