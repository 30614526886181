import { createSlice } from "@reduxjs/toolkit";

export const bigtitleSlice = createSlice({
	name: "bigtitleSlice",
	initialState: {
		text: "",
	},
	reducers: {
		setText: (currentSlice, action) => {
			currentSlice.text = action.payload;
		}
	},
});

export const bigtitleReducer = bigtitleSlice.reducer;
export const { setText } = bigtitleSlice.actions;