import s from "./style.module.css";
import { useEffect, useState } from "react";
export function ScrollBar({ pourcentage, ...props }) {
	const [isScroll, setIsScroll] = useState(false);
	const { blend, color } = props;
	useEffect(() => {
		setIsScroll(true);

		const timer = setTimeout(() => {
			setIsScroll(false);
		}, 800);

		return () => {
			clearTimeout(timer);
		};
	}, [pourcentage]);

	return (
		<div>
			<div className={`${s.container} ${isScroll ? s.show : ""}`}>
				<div
					className={`${s.bar}`}
					style={{
						width: pourcentage + "%",
						mixBlendMode: blend ? "difference" : "normal",
						background: blend ? color : "#000",
					}}
				/>
			</div>
		</div>
	);
}
