import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import s from "./style.module.scss";

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFrom, setTo, setRoll } from "store/transition/transition-slice";
import { Animation } from "./animation";

let from = null;
let to = null;
let start = [];
let end = [];

const PageAnimation = (props) => {
	const [isCurrent, setIsCurrent] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [isTransition, setIsTransition] = useState(false);

	const container = useRef();
	const dispatch = useDispatch();

	const location = useLocation();
	const rootPath = location.pathname == "/";
	function initial() {
		return {
			opacity: 0.99999,
		};
	}
	function animate() {
		return {
			opacity: 1,
		};
	}
	function exit() {
		return {
			opacity: 0.99999,
		};
	}

	const classList = props.children.props?.className
		? props.children.props?.className.split(" ")
		: [];
	const isHome = classList.includes("home") ? "" : " " + s.overflowHidden;

	const classIsActive = isActive || isTransition ? " " + s.active : "";
	const classIsCurrent = isCurrent ? " " + s.current : "";
	const classIsTransition = isTransition ? " " + s.transition : "";
	const classes =
		s.page + classIsActive + classIsCurrent + classIsTransition + isHome;


	useEffect(() => {
		from = to;
		to = container.current;

		const Anim = new Animation();

		Anim.set(to, from, rootPath);
	}, [container]);

	return (
		<motion.div
			ref={container}
			className={classes}
			initial={initial}
			animate={animate}
			exit={exit}
			transition={{ duration: 0.9 }}
			onAnimationStart={() => {
				setIsActive(false);
				setIsCurrent(false);
				setIsTransition(true);
				document.body.classList.add("transition-progress");
			}}
			onAnimationComplete={() => {
				setIsActive(true);
				setIsCurrent(true);
				setIsTransition(false);
				document.body.classList.remove("transition-progress");
			}}
		>
			<div className={s.pageInt}>{props.children}</div>
		</motion.div>
	);
};
export default PageAnimation;
