// import { useState } from "react";
import s from "./style.module.css";
import { Category } from "components/Category/Category";

export function Categories({ categoriesList, styleItem }) {
	return (
		<div
			className={`${styleItem != null ? s.containerIndex : s.container}`}
		>
			{categoriesList.map((category) => (
				<Category
					key={category.id}
					title={category.attributes.Name}
					styleItem={styleItem}
				/>
			))}
		</div>
	);
}
