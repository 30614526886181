import { createSlice } from "@reduxjs/toolkit";

export const scrollerSlice = createSlice({
	name: "scrollerSlice",
	initialState: {
		elements: [],
		offset: null,
	},
	reducers: {
		addElement: (currentSlice, action) => {
			const { elements } = currentSlice;
			const existingIndex = elements.findIndex(
				(el) => el.el === action.payload.el
			);
			return {
				...currentSlice,
				elements:
					existingIndex === -1
						? [...elements, action.payload]
						: [
								...elements.slice(0, existingIndex),
								{
									...elements[existingIndex],
									...action.payload,
								},
								...elements.slice(existingIndex + 1),
						  ],
			};
		},
		setOffset: (currentSlice, action) => {
			currentSlice.offset = action.payload;
		},
	},
});

export const scrollerReducer = scrollerSlice.reducer;
export const { addElement, setOffset } = scrollerSlice.actions;

/*

	01
	addElement: (currentSlice, action) => {
		currentSlice.elements = action.payload;
	},

	02 Add ok
	addElement: (currentSlice, action) => {
	return {
		...currentSlice,
		elements: currentSlice.elements.concat(action.payload),
	};





*/
