import axios from "axios";

export class ProjectAPI {
	constructor() {
		this.data = {};
	}
	static async fetchAll() {

		if (this.data.fetchAll) {
			return this.data.fetchAll;
		} else {

			return (
				await axios.get(
					`${process.env.REACT_APP_BASE_URL}/api/projects/?populate=*`
				)
			).data.data;
		}
	}

	static async fetchHome() {
		return (
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/home/?populate=deep,4`
			)
		).data.data.attributes.projects.data;
	}

	static async fetchIndex() {
		return (
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/index/?populate=deep,3`
			)
		).data.data;
	}

	static async fetchAbout() {
		return (
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/about/?populate=deep`
			)
		).data.data;
	}

	static async fetchClients() {
		return (
			await axios.get(`${process.env.REACT_APP_BASE_URL}/api/clients?populate=deep,2`)
		).data;
	}

	static async fetchSecteurs() {
		return (
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/types/?populate=deep,3`
			)
		).data;
	}


	static async fetchUploads() {
		return (
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/upload/files`
			)
		).data;
	}

}
// refactorise le code pour que les données ne se recharge pas à chaque appel
