import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBlend, setColor } from "store/header/header-slice";
import s from "./style.module.scss";
import { AboutComponents } from "components/AboutComponents/AboutComponents";
import { Scroller } from "components/Scroller/Scroller";

import PageAnimation from "components/pageAnimation/pageAnimation";
import { useNavigate } from "react-router-dom";
export function PageAbout(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const sectionList = useSelector((store) => store.ABOUT.data);

	useEffect(() => {
		dispatch(setBlend(true));
		dispatch(setColor("#fff"));
	}, [dispatch]);

	function handlerClick(e) {
		e.preventDefault();
		const href = e.target.getAttribute("href");
		navigate(href);
	}

	useEffect(() => {
		let links;
		setTimeout(() => {
			// dispatch(setBlend(true));
			// dispatch(setColor("#fff"));

			links = document.querySelectorAll(".about a");
			links.forEach((link) => {
				link.addEventListener("click", handlerClick);
			});
		}, 200);

		return () => {
			if (!links) return;
			links.forEach((link) => {
				link.removeEventListener("click", handlerClick);
			});
			links = null;
			// dispatch(setBlend(true));
			// dispatch(setColor("#fff"));
		};
	}, [sectionList]);

	return (
		<PageAnimation>
			<Scroller save={true} slug={"about"}>
				<div className={`about ${s.container}`}>
					{sectionList.length > 0 &&
						sectionList.map((section, index) => (
							<div key={index} className={s.wrapper}>
								<AboutComponents
									key={index}
									type={section.__component}
									content={section}
									keysection={index}
								/>
							</div>
						))}
				</div>
			</Scroller>
		</PageAnimation>
	);
}
