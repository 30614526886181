import { Scroller } from "components/Scroller/Scroller";
import { ThumbCard } from "components/ThumbCard/ThumbCard";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import s from "./style.module.css";
import { useState } from "react";

export function ProjectList( DOM ) {
	const projectList = useSelector((store) => store.PROJECT.projectList);
	const navigate = useNavigate();

	const [direction, setDirection] = useState("x");

	return (
		<div className={s.wrapper}>
			<Scroller direction={direction} overflow={false} delegateGlobal={true} save={true} slug={'home'}>
				<div className={s.container}>
					{projectList.map((project) => (
						<ThumbCard
							key={project.id}
							title={project.attributes.Title}
							categories={project.attributes.categories.data}
							thumbImg={project.attributes.Thumbnail.data}
							onClickNavigate={() =>
								navigate("/projet/" + project.attributes.slug)
							}
						/>
					))}
				</div>
			</Scroller>
		</div>
	);
}
