import { createSlice } from "@reduxjs/toolkit";

export const indexSlice = createSlice({
	name: "indexSlice",
	initialState: {
		data: {},
	},
	reducers: {
		setDataIndex: (currentSlice, action) => {
			currentSlice.data = action.payload;
		},
	},
});

export const indexReducer = indexSlice.reducer;
export const { setDataIndex } = indexSlice.actions;
