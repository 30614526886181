// import { useEffect } from "react";
import { useSelector } from "react-redux";
import s from "./style.module.css";
import { useEffect, useRef, useState } from "react";

import { gsap } from "gsap";

export function BigTitle({ props }) {
	const text = useSelector((store) => store.BIGTITLE.text);
	const xPour = useSelector((store) => store.HEADER.pourcentage);
	const textEl = useRef();

	const [newWidthTextEl, setNewWidthTextEl] = useState();
	const [isTextEl, setIsTextEl] = useState();
	// const [x, setX] = useState();
	// const [lastXpour, setLastXpour] = useState(0);
	const [first, setFirst] = useState(true);
	// const [firstAnim, setFirstAnim] = useState(true);

	const [winWidth, setWinWidth] = useState(0);

	const resizeWindows = () => {
		window.addEventListener("resize", () => {
			setWinWidth({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		});
	};

	useEffect(() => {
		setWinWidth({ width: window.innerWidth, height: window.innerHeight });
		resizeWindows();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (isTextEl && text) {
				const widthTextEl = isTextEl.offsetWidth;
				setNewWidthTextEl((widthTextEl - winWidth.width + 20) / 100);
			}
		}, 500);
	}, [winWidth, isTextEl, text]);

	useEffect(() => {
		if(!isTextEl) return;
		
		const tl = gsap.timeline();
		const pourcentage = -xPour;
		const x = newWidthTextEl * pourcentage;

		tl.to(isTextEl, { x: x, duration: 2, ease: "power4.out" });
	}, [xPour]);

	return (
		<div className={s.container}>
			<div
				ref={(textEl) => {
					setIsTextEl(textEl);
				}}
				className={`${s.text}`}
			>
				{text}
			</div>
		</div>
	);
}
