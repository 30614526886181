import { createSlice } from "@reduxjs/toolkit";

export const headerSlice = createSlice({
	name: "headerSlice",
	initialState: {
		pourcentage: 0,
		blend: false,
		color: "red",
	},
	reducers: {
		setPourcentage: (currentSlice, action) => {
			currentSlice.pourcentage = action.payload;
		},
		setBlend: (currentSlice, action) => {
			currentSlice.blend = action.payload;
		},
		setColor: (currentSlice, action) => {
			currentSlice.color = action.payload;
		},
	},
});

export const headerReducer = headerSlice.reducer;
export const { setPourcentage, setBlend, setColor } = headerSlice.actions;
