import { createSlice } from "@reduxjs/toolkit";

export const transitionSlice = createSlice({
	name: "transitionSlice",
	initialState: {
		from: null,
		to: null,
		roll: false
	},
	reducers: {
		setFrom: (currentSlice, action) => {
			currentSlice.from = action.payload;
		},
		setTo: (currentSlice, action) => {
			currentSlice.to = action.payload;
		},
		setRoll: (currentSlice, action) => {
			currentSlice.roll = !currentSlice.roll;
		},

	},
});

export const transitionReducer = transitionSlice.reducer;
export const { setFrom, setTo, setRoll } = transitionSlice.actions;
