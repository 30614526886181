import s from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AboutComponents } from "components/AboutComponents/AboutComponents";
import { Scroller } from "components/Scroller/Scroller";

import PageAnimation from "components/pageAnimation/pageAnimation";
import { useEffect } from "react";
import { setBlend, setColor } from "store/header/header-slice";
export function PageOther({ slug }) {
	const dispatch = useDispatch();

	const pages = useSelector((store) => store.OTHERS.data);
	if (Object.keys(pages).length === 0) return <div>loading</div>;

	const data = pages.filter((page) => page.attributes.slug === slug)[0]
		?.attributes;

	const colorText = data?.color === "#fff" ? "#fff" : data.color;

	// setTimeout(() => {
	// 	dispatch(setBlend(true));
	// 	dispatch(setColor(colorText));
	// }, 200);

	return (
		<PageAnimation>
			<Scroller save={true} slug={"others"}>
				<div
					className={`others ${s.container}`}
					style={{
						color: data.color,
						background:
							data.background !== ""
								? data.background
								: "inherit",
					}}
				>
					<div key="0" className={s.wrapper}>
						<div>
							{/* <a
								href="#about"
								onClick={(e) => {
									e.preventDefault();
									navigate("/page/about");
									document.title =
										"À propos — studio kiss - Studio de direction artistique & de création graphique";
								}}
							>
								Retour
							</a> */}
							<AboutComponents
								key="0"
								type="about.texte-d-introduction"
								content={{
									Introduction: data.title,
								}}
							/>
						</div>
						<AboutComponents
							key="1"
							type="about.description"
							content={{
								Description: data.content,
							}}
						/>
					</div>
				</div>
			</Scroller>
		</PageAnimation>
	);
}
